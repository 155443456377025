import React, {useState} from "react";
import { useLocalization, LocalizedLink as Link } from "gatsby-theme-i18n";

import Layout from "../components/Layout";
import HelpMenu from "../components/HelpMenu";
import QuestionEN14 from "../components/Questions/QuestionEN14";
import QuestionDE14 from "../components/Questions/QuestionDE14";
import QuestionFR14 from "../components/Questions/QuestionFR14";

export default function HelpPage() {
    const { locale } = useLocalization();
    const [showHelpMenu, setShowHelpMenu] = useState(true);
        
    const toggleMenu = () => {
        setShowHelpMenu(prev => !prev);
    }

    return (
        <Layout>
            <main className="static help-wrap">               
                <div className="hamburger-help-wrap">
                    <div className={showHelpMenu ? "hamburger-help mobile-only open" : "hamburger-help mobile-only"} onClick={toggleMenu}> </div>     
                </div>
                <div className={showHelpMenu ? "help-menu open" : "help-menu close"}><HelpMenu /></div>
                <div className={showHelpMenu ? "help-content close" : "help-content open"}>
                    { (locale == "en") && <QuestionEN14 /> }
                    { (locale == "de") && <QuestionDE14 /> }
                    { (locale == "fr") && <QuestionFR14 /> }
                </div>
            </main>
        </Layout>
    );
}
