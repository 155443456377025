import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const QuestionEN14 = () => {

    return (
        <>
       test en
        </>
    );
}

export default QuestionEN14;